import { ReagentOverviewForInstrument } from '@dewire/models/definitions/api-response/reagent-overview-for-instrument';
import TableRowItem from 'components/styled-components/items/TableRowItem';
import TableRow from 'components/styled-components/table/TableRow';
import { formatDisplayDate } from 'helpers/formatters/date-formatter';

interface ReagentOverviewForInstrumentRowProps {
  reagent: ReagentOverviewForInstrument;
}

function ReagentOverviewForInstrumentRow({ reagent }: ReagentOverviewForInstrumentRowProps) {
  return (
    <TableRow gridExpression="20% 20% 15% 12% 17% 20%">
      <TableRowItem>{formatDisplayDate(reagent.pairingDate)}</TableRowItem>
      <TableRowItem>{reagent.decouplingDate ? formatDisplayDate(reagent.decouplingDate) : ''}</TableRowItem>
      <TableRowItem>{reagent.containerId}</TableRowItem>
      <TableRowItem>{reagent.volume}</TableRowItem>
      <TableRowItem>{reagent.openingDate ? formatDisplayDate(reagent.openingDate) : ''}</TableRowItem>
      <TableRowItem>{reagent.expiryDate ? formatDisplayDate(reagent.expiryDate) : ''}</TableRowItem>
    </TableRow>
  );
}
export default ReagentOverviewForInstrumentRow;
