import { ReagentInstrumentDetails } from '@dewire/models/definitions/api-response/reagent-lot-with-reagents-response';
import InfoItem from 'components/styled-components/items/PopoverInfoItem';
import { formatDisplayDate } from 'helpers/formatters/date-formatter';

interface InstrumentReagentStatusCardProps {
  instrument: ReagentInstrumentDetails;
}

function InstrumentReagentStatusCard({ instrument }: InstrumentReagentStatusCardProps) {
  return (
    <>
      <InfoItem>
        <h4>Instrument ID: </h4>
        {instrument.instrumentId}
      </InfoItem>
      <InfoItem>
        <h4>Distributor: </h4>
        {instrument.distributor}
      </InfoItem>
      <InfoItem>
        <h4>Customer: </h4>
        {instrument.customer}
      </InfoItem>
      <InfoItem>
        <h4>Site: </h4>
        {instrument.site}
      </InfoItem>
      <InfoItem>
        <h4>Last known volume: </h4>
        {instrument.usedVolume}
      </InfoItem>
      <InfoItem>
        <h4>Pairing date: </h4>
        {formatDisplayDate(instrument.pairingDate)}
      </InfoItem>
      <InfoItem>
        <h4>Decoupling date: </h4>
        {instrument.decouplingDate ? formatDisplayDate(instrument.decouplingDate) : ''}
      </InfoItem>
    </>
  );
}

export default InstrumentReagentStatusCard;
