import { ReagentLotReagents } from '@dewire/models/definitions/api-response/reagent-lot-with-reagents-response';
import { DefaultTheme, useTheme } from 'app/AppStyling';
import ExpandArrowIcon from 'assets/icons/ExpandArrowIcon';
import InstrumentReagentStatusCard from 'components/cards/reagent/InstrumentReagentStatusCard';
import InfoIconPopover from 'components/popovers/InfoIconPopover';
import InTableLink from 'components/styled-components/buttons/InTableLink';
import InTableStatusButton from 'components/styled-components/buttons/InTableStatusButton';
import TableRowItem from 'components/styled-components/items/TableRowItem';
import TableRow from 'components/styled-components/table/TableRow';

interface ReagentLotContainerRowProps {
  reagentContainer: ReagentLotReagents;
}

// TODO: change to enum ReagentStatus
const getStatusColor = (status: string) => {
  switch (status) {
    case 'In use':
      return DefaultTheme.background.green;
    case 'Expired':
    case 'Finished':
      return DefaultTheme.background.red;
    default:
      return DefaultTheme.background.lightest;
  }
};
const getStatusText = (reagent: ReagentLotReagents) => {
  switch (reagent.status) {
    case 'In use':
      return reagent.instrument?.instrumentId as string; // in use means instrument prop cant be null
    case 'Expired':
      return `${reagent.status}: ${reagent.expiryDate}`;
    default:
      return reagent.status;
  }
};

function ReagentLotContainerRow({ reagentContainer }: ReagentLotContainerRowProps) {
  const blueColor = useTheme().colors.blue;
  return (
    <TableRow className="reagentContainerTable" gridExpression="15% 19% 17% 15% 17% 1%">
      <TableRowItem>{reagentContainer.id}</TableRowItem>
      <TableRowItem>{reagentContainer.usedVolume}</TableRowItem>
      <TableRowItem>{reagentContainer.openingDate}</TableRowItem>
      <TableRowItem>{reagentContainer.expiryDate}</TableRowItem>
      {reagentContainer.status === 'In use' && reagentContainer.instrument ? (
        <InfoIconPopover
          leftAlign={0}
          link={reagentContainer.instrument.instrumentId}
          linkColor={blueColor}
          icon={
            <InTableStatusButton
              $color={getStatusColor(reagentContainer.status)}
              $hoverColor={reagentContainer.status === 'In use' ? DefaultTheme.background.hoverGreen : ''}
            >
              {getStatusText(reagentContainer)}
            </InTableStatusButton>
          }
        >
          <InstrumentReagentStatusCard instrument={reagentContainer.instrument} />
        </InfoIconPopover>
      ) : (
        <InTableStatusButton
          $color={getStatusColor(reagentContainer.status)}
          $hoverColor={reagentContainer.status === 'In use' ? DefaultTheme.background.hoverGreen : ''}
        >
          {getStatusText(reagentContainer)}
        </InTableStatusButton>
      )}

      <InTableLink to={`${reagentContainer.id}`}>
        {reagentContainer.numberOfPairedInstruments}
        <ExpandArrowIcon open={false} color="black" />
      </InTableLink>
    </TableRow>
  );
}
export default ReagentLotContainerRow;
