import { InstrumentPairing } from '@dewire/models/definitions/api-response/reagent-with-paired-instruments';
import TableRowItem from 'components/styled-components/items/TableRowItem';
import TableRow from 'components/styled-components/table/TableRow';
import { formatDisplayDate } from 'helpers/formatters/date-formatter';

interface ReagentPairedInstrumentRowProps {
  pairedInstrument: InstrumentPairing;
  volume: number | undefined;
}
function ReagentPairedInstrumentRow({ pairedInstrument, volume }: ReagentPairedInstrumentRowProps) {
  return (
    <TableRow className="reagentContainerTable" gridExpression="16% 17% 15% 14% 15% 14% 7%">
      <TableRowItem>{formatDisplayDate(pairedInstrument.pairingDate)}</TableRowItem>
      <TableRowItem>
        {pairedInstrument.decouplingDate && formatDisplayDate(pairedInstrument.decouplingDate)}
      </TableRowItem>
      <TableRowItem>{pairedInstrument.instrumentId}</TableRowItem>
      <TableRowItem>{pairedInstrument.distributor.name}</TableRowItem>
      <TableRowItem>{pairedInstrument.customer?.name ?? ''}</TableRowItem>
      <TableRowItem>{pairedInstrument.site?.name ?? ''}</TableRowItem>
      <TableRowItem>{volume}</TableRowItem>
    </TableRow>
  );
}

export default ReagentPairedInstrumentRow;
